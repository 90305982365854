import cn from "classnames";

import type { AdInfoProps } from "./types";

import styles from "./AdInfo.module.scss";
import { Icon } from "../../Icon/Icon";

const AdInfo = ({
  creativeId,
  privacyUrl,
  closeModal,
  modalRef,
}: AdInfoProps) => (
  <div ref={modalRef} className={styles.container}>
    <div className={styles.info}>
      <div className={styles.close}>
        <span
          className={styles.closeSpan}
          onClick={(e) => {
            closeModal(e);
          }}
        >
          <Icon variant="close" size="small" />
        </span>
      </div>
      <h3 className={styles.modalTitle}>Varför ser du denna annons?</h3>
      <p className={styles.modalText}>
        Du ser denna annons eftersom sajten du besöker till stor del finansieras
        av annonsintäkter. Vissa annonser ser du för att vi tror att de kan
        intressera dig. Detta antagande baserar vi på din tidigare aktivitet på
        Schibsted Medias sajter och appar.
      </p>

      <p className={styles.modalText}>
        <a
          href={privacyUrl}
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Hantera dina annonsinställningar
        </a>
      </p>

      <h3 className={styles.modalTitle}>Om Schibsted Media och annonsering</h3>

      <p className={styles.modalText}>
        Schibsted Media är en etablerad mediekoncern med huvudkontor i Oslo, som
        äger sajter som till exempel Omni, Svenska Dagbladet, Aftonbladet och
        Tv.nu.
      </p>

      <p className={styles.modalText}>
        <a
          href="https://info.privacy.schibsted.com/se/smarta-annonser-och-vad-de-innebar-for-dig/"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Läs mer om annonsering på Schibsted Media
        </a>
      </p>

      <div className={cn([styles.footer, styles.modalText])}>
        Omni är en del av{" "}
        <a
          href="https://schibsted.com/"
          className={styles.footer__link}
          target="_blank"
          rel="noreferrer"
        >
          Schibsted Media
        </a>
        <p className={cn([styles.footer__creative, styles.modalText])}>
          Annonsens ID-nummer: {creativeId}
        </p>
      </div>
    </div>
  </div>
);

export { AdInfo };
