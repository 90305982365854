import cn from "classnames";
import { useEffect, useState } from "react";

import type { AdInfoWrapperProps } from "./types";

import styles from "./AdInfo.module.scss";
import { getPulseTracker } from "../../../pulse";
import { useModal } from "../../../hooks/useModal";
import { AdInfo } from "./AdInfo";
import { Icon } from "../../Icon/Icon";

const AdInfoWrapper = ({
  creativeId,
  isLoggedIn,
  isLoading,
  placementClassName,
  isWide,
  isFullscreenScroll,
}: AdInfoWrapperProps) => {
  const [privacyUrl, setPrivacyUrl] = useState("");
  const { ModalWrapper, isOpen, ref, open, close } = useModal();

  const modal = isOpen ? (
    <ModalWrapper>
      <AdInfo
        privacyUrl={privacyUrl}
        creativeId={creativeId}
        closeModal={close}
        modalRef={ref}
      />
    </ModalWrapper>
  ) : null;

  useEffect(() => {
    const createPrivacyUrl = async () => {
      if (isLoggedIn) {
        setPrivacyUrl("https://privacysettings.schibsted.com/");
      } else {
        const pulse = getPulseTracker();
        const pulseEnvId = await pulse?.pulseTracker.getEnvironmentId();
        setPrivacyUrl(
          `https://privacysettings.schibsted.com/?environment_id=${pulseEnvId}`,
        );
      }
    };

    createPrivacyUrl();
  }, [isLoggedIn]);

  return (
    <>
      <button
        onClick={(e) => {
          if (!isLoading) {
            open(e);
          }
        }}
        className={cn(
          styles.label,
          styles.marking,
          isWide && styles.wide,
          isFullscreenScroll && styles.fullscreenScroll,
          placementClassName && styles[placementClassName],
          isLoading && styles.label__notloaded,
        )}
      >
        <span className={styles.text}>Annons</span>
        <span className={styles.icon}>
          {isLoading ? null : (
            <Icon variant="settings" size="small" ariaLabel="Inställningar" />
          )}
        </span>
      </button>
      {modal}
    </>
  );
};

export { AdInfoWrapper };
