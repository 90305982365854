import type { Permissions } from "../../../../services/lbmpService";

import { getPulseTracker } from "../../../../pulse";
import {
  getAnonymousPermissions,
  getUserPermissions,
} from "../../../../services/lbmpService";

type Asset = { [key: string]: string };
type AssetsMap = {
  [key: string]: Asset;
};

const assetsMap: AssetsMap = {
  glimr: {
    purpose: "sdrn:omnise:processingpurpose:targeted_advertising",
    data: "sdrn:schibsted-media:datacategory:geolocation",
  },
};

const getGeoTrackingPermission = async (
  loggedIn: boolean,
  user_id: string,
  sig: string,
): Promise<boolean> => {
  try {
    const purpose = assetsMap.glimr.purpose;

    let permissions: Permissions = [];

    const browser = window as any;

    if (!browser) {
      return false;
    }

    if (loggedIn && sig) {
      const response = await getUserPermissions(
        `sdrn:schibsted.com:user:${user_id}`,
        sig,
      );
      if (!response.data) return false;

      permissions = response.data?.permissions || [];
      return !!permissions.find((permission) => permission.purpose === purpose);
    }

    const pulseTracker = getPulseTracker();
    const pulseEnvId = await pulseTracker?.pulseTracker.getEnvironmentId();

    const response = await getAnonymousPermissions(
      `sdrn:schibsted:environment:${pulseEnvId}`,
    );
    if (!response.data) return false;

    permissions = response.data?.permissions || [];

    return !!permissions.find((permission) => permission.purpose === purpose);
  } catch (e) {
    console.error(`geotracking permission failure: ${e}`);
    return false;
  }
};

export { getGeoTrackingPermission };
