import type { Theme } from "../../../../types/theme";

type AdPlacementMap = {
  [key in AdPlacementContext]: {
    [key in Theme]: {
      [key in AdPlacements]?: { [key: string]: string };
    };
  };
};

type AdPlacementContext = "article" | "feed";

const validAdPlacementContexts: AdPlacementContext[] = ["article", "feed"];

type AdPlacements =
  | "beforeLinks"
  | "afterLinks"
  | "widescreen1"
  | "widescreen2"
  | "topPanorama"
  | "fullscreen"
  | "sponsoredBanner"
  | "feedPanorama"
  | "takeover"
  | "contentDisplay";

const validAdPlacements: AdPlacements[] = [
  "beforeLinks",
  "afterLinks",
  "widescreen1",
  "widescreen2",
  "topPanorama",
  "fullscreen",
  "sponsoredBanner",
  "feedPanorama",
  "takeover",
  "contentDisplay",
];

interface ValidAdPlacement {
  placementContext: AdPlacementContext;
  placementWebName: AdPlacements;
  correct: true;
}
interface InvalidAdPlacement {
  placementContext: string;
  placementWebName: string;
  correct: false;
}

function parseAdPlacement(
  placement: string,
): InvalidAdPlacement | ValidAdPlacement {
  const [placementContext, placementWebName] = placement.split(".");

  if (
    validAdPlacements.includes(placementWebName as AdPlacements) &&
    validAdPlacementContexts.includes(placementContext as AdPlacementContext)
  ) {
    return {
      placementContext: placementContext as AdPlacementContext,
      placementWebName: placementWebName as AdPlacements,
      correct: true,
    };
  }

  return { placementContext, placementWebName, correct: false };
}

const adPlacementMap: AdPlacementMap = {
  article: {
    core: {
      beforeLinks: {
        desktop: "articleModulOne",
        mobile: "mobileArticlePanorama",
      },
      afterLinks: {
        desktop: "dynamicDesktop",
        mobile: "dynamicMobileWeb",
      },
      widescreen1: {
        desktop: "articleWidescreen",
      },
      sponsoredBanner: {
        desktop: "stickyTop",
        mobile: "stickyTop",
      },
    },
    ekonomi: {
      beforeLinks: {
        desktop: "articleModulOne",
        mobile: "mobileArticlePanorama",
      },
      afterLinks: {
        desktop: "dynamicDesktop",
        mobile: "dynamicMobileWeb",
      },
      widescreen1: {
        desktop: "articleWidescreen",
      },
      sponsoredBanner: {
        desktop: "stickyTop",
        mobile: "stickyTop",
      },
    },
  },
  feed: {
    core: {
      widescreen1: {
        desktop: "widescreen1",
      },
      widescreen2: {
        desktop: "widescreen2",
      },
      topPanorama: {
        desktop: "topPanorama",
      },
      feedPanorama: {
        desktop: "feedPanorama",
      },
      fullscreen: {
        mobile: "fullscreen",
      },
      takeover: {
        desktop: "takeoverDesktop",
        mobile: "takeoverMobile",
      },
      contentDisplay: {
        desktop: "newsfeedDesktop",
        mobile: "newsfeed",
      },
      sponsoredBanner: {
        desktop: "stickyTop",
        mobile: "stickyTop",
      },
    },
    ekonomi: {
      widescreen1: {
        desktop: "widescreen1",
      },
      widescreen2: {
        desktop: "widescreen2",
      },
      topPanorama: {
        desktop: "topPanorama",
      },
      feedPanorama: {
        desktop: "feedPanorama",
      },
      fullscreen: {
        mobile: "fullscreen",
      },
      takeover: {
        desktop: "takeoverDesktop",
        mobile: "takeoverMobile",
      },
      contentDisplay: {
        desktop: "newsfeedDesktop",
        mobile: "newsfeed",
      },
      sponsoredBanner: {
        desktop: "stickyTop",
        mobile: "stickyTop",
      },
    },
  },
};

export { adPlacementMap, parseAdPlacement };
export type { AdPlacementContext };
