import type {
  HasSessionFailureResponse,
  HasSessionSuccessResponse,
} from "@schibsted/account-sdk-browser";

import { getIdentityInstance } from "./getIdentityInstance";

const isError = (
  response: HasSessionSuccessResponse | HasSessionFailureResponse,
): response is HasSessionFailureResponse =>
  (response as HasSessionFailureResponse).error !== undefined;

const sessionObj = { sig: "", userId: "" };

const getIdentitySession = async (isLoggedIn: boolean) => {
  if (!isLoggedIn) {
    return sessionObj;
  }

  try {
    const identity = getIdentityInstance();
    const session = await identity.hasSession();

    if (isError(session)) {
      throw new Error(
        `Error of type ${session.error.type} with description ${session.error.description} when getting sig`,
      );
    }
    sessionObj.sig = session.sig;
    sessionObj.userId = session.userId.toString();
    return sessionObj;
  } catch (e) {
    console.error(e);

    return sessionObj;
  }
};

export { isError, getIdentitySession };
