import type { FetchError } from "../types/fetchError";
import type { ErrorResponse, OkResponse } from "../types/serviceResponse";

type Permission = { purpose: string; data: string[] };

type PermissionResponse = {
  permissions: Permissions;
};

type Permissions = Permission[];

const lbmpBaseUrl = process.env.NEXT_PUBLIC_LBMP_API_BASE;
const lbmpPublisher = process.env.NEXT_PUBLIC_LBMP_PUBLISHER;

const validateSdrn = (sdrn: string): string => {
  const pulseSdrnRegex =
    /^sdrn:[a-zA-Z0-9_.-]*:[a-zA-Z0-9_.-]*:[a-zA-Z0-9_.-]*$/;
  if (!pulseSdrnRegex.test(sdrn)) {
    throw new Error(`Invalid pulse sdrn: ${sdrn}`);
  }
  return sdrn;
};

const getAnonymousPermissions = async (
  sdrn: string,
): Promise<OkResponse<PermissionResponse> | ErrorResponse> => {
  const validatedSdrn = validateSdrn(sdrn);
  const url = `${lbmpBaseUrl}/anonymous/${lbmpPublisher}/user/${validatedSdrn}/permissions`;

  try {
    const response = await fetch(url, {
      headers: { "Content-Type": "application/json;charset=UTF-8" },
    });

    if (!response.ok) {
      return {
        data: null,
        error: {
          status: response.status,
          statusText: response.statusText,
          message: `Failed to fetch anonymous permissions ${response.status} ${response.statusText}`,
        },
      };
    }

    const data = await response.json();

    return { data, error: null };
  } catch (e) {
    const error = e as Error;
    console.error(error);
    return {
      data: null,
      error: {
        message: error.message,
      } as FetchError,
    };
  }
};

const getUserPermissions = async (
  sdrn: string,
  sig: string,
): Promise<OkResponse<PermissionResponse> | ErrorResponse> => {
  const validatedSdrn = validateSdrn(sdrn);
  const url = `${lbmpBaseUrl}/spid/${lbmpPublisher}/user/${validatedSdrn}/permissions`;

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `SPIDSIG ${sig}`,
      },
    });

    if (!response.ok) {
      return {
        data: null,
        error: {
          status: response.status,
          statusText: response.statusText,
          message: `Failed to fetch user permissions ${response.status} ${response.statusText}`,
        },
      };
    }

    const data = await response.json();
    return { data, error: null };
  } catch (e) {
    const error = e as Error;
    console.error(error);
    return {
      data: null,
      error: {
        message: error.message,
      } as FetchError,
    };
  }
};

export { getUserPermissions, getAnonymousPermissions };
export type { Permissions };
