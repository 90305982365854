import Link from "next/link";
import cn from "classnames";
import { Heading, HeadingLevel } from "@ariakit/react";
import { useEffect, useState } from "react";

import type { AstNative } from "@omni/ads";

import teaserStyles from "../Teaser.module.scss";
import styles from "./TeaserContentDisplay.module.scss";
import { useTcfPermission } from "../../../tcf/helpers";
import { Icon } from "../../Icon/Icon";
import { useModal } from "../../../hooks/useModal";
import { AdInfo } from "../../Ad/Adinfo/AdInfo";
import { getPulseTracker } from "../../../pulse";
import { TeaserImage } from "../TeaserImage/TeaserImage";

const TeaserContentDisplay = ({
  ad,
  layout = "Large",
  isLoggedIn,
  creativeId,
}: {
  ad: AstNative;
  isLoggedIn: boolean;
  creativeId: string;
  layout?: "Small" | "Large";
}) => {
  const advertisingConsent = useTcfPermission("CMP:advertising");
  const adClicked = () => ad.clickTrackers.map((ct) => fetch(ct));

  const [privacyUrl, setPrivacyUrl] = useState("");
  const { ModalWrapper, isOpen, ref, open, close } = useModal();

  const modal = isOpen ? (
    <ModalWrapper>
      <AdInfo
        privacyUrl={privacyUrl}
        creativeId={creativeId}
        closeModal={close}
        modalRef={ref}
      />
    </ModalWrapper>
  ) : null;

  useEffect(() => {
    const createPrivacyUrl = async () => {
      if (isLoggedIn) {
        setPrivacyUrl("https://privacysettings.schibsted.com/");
      } else {
        const pulse = getPulseTracker();
        const pulseEnvId = await pulse?.pulseTracker.getEnvironmentId();
        setPrivacyUrl(
          `https://privacysettings.schibsted.com/?environment_id=${pulseEnvId}`,
        );
      }
    };

    createPrivacyUrl();
  }, [isLoggedIn]);

  return (
    <article className={cn([teaserStyles.teaserContainer, styles.container])}>
      <div className={styles.label}>
        <span>ANNONS</span>
        <span
          role="button"
          onClick={(e) => {
            open(e);
          }}
          className={styles.button}
        >
          <Icon variant="settings" size="small" />
        </span>
      </div>
      <Link
        href={ad.clickUrl}
        onClick={() => {
          if (advertisingConsent) {
            adClicked();
          }
        }}
        className={teaserStyles.teaserLargeImage}
        prefetch={false}
      >
        <div
          className={cn([
            teaserStyles.teaser,
            teaserStyles["regular"],
            teaserStyles[layout.toLowerCase()],
          ])}
        >
          <HeadingLevel>
            {ad?.image?.url && (
              <TeaserImage
                size="large"
                imageUrl={ad.image.url}
                style="regular"
                hasPriorityImage
              />
            )}
            <div className={teaserStyles.teaserContent}>
              <div
                className={cn([
                  teaserStyles["regular"],
                  teaserStyles.teaserTextContent,
                ])}
              >
                <HeadingLevel>
                  <Heading className={styles.title}>{ad.title}</Heading>
                </HeadingLevel>
                <p className={styles.text}>{ad.body}</p>
                <div className={styles.footer}>
                  {`Extern länk | ${ad.sponsoredBy}`}
                </div>
              </div>
            </div>
          </HeadingLevel>
        </div>
      </Link>
      {modal}
    </article>
  );
};

export { TeaserContentDisplay };
