"use client";

import { useEffect } from "react";

import type { Article } from "../types/content";

import { useTcfPermission } from "../tcf/helpers";

interface OrvestoEvent {
  cmd: string;
  val?: string;
}

function orvestoTrack(events: OrvestoEvent[]): void {
  if (!window) {
    return;
  }

  if (!window._cInfo) {
    window._cInfo = [];
  }

  if (events.length === 0) {
    return;
  }

  window._cInfo.push(...events, { cmd: "_executeTracking" });
}

function articleViewEvent(article?: Article): OrvestoEvent[] {
  if (!article) {
    return [];
  }
  return [
    {
      cmd: "_trackContentPath",
      val: `${article.category.title}/${
        article.story?.title || article.title.value
      }`,
    },
  ];
}

function topnewsViewEvent(): OrvestoEvent[] {
  return [
    {
      cmd: "_trackContentPath",
      val: "start",
    },
  ];
}

function categoryViewEvent(category?: string): OrvestoEvent[] {
  if (!category) {
    return [];
  }
  return [
    {
      cmd: "_trackContentPath",
      val: category.toLowerCase(),
    },
  ];
}

function latestViewEvent(): OrvestoEvent[] {
  return [
    {
      cmd: "_trackContentPath",
      val: "senaste",
    },
  ];
}

function topicViewEvent(topicTitle?: string): OrvestoEvent[] {
  if (!topicTitle) {
    return [];
  }

  return [
    {
      cmd: "_trackContentPath",
      val: topicTitle.toLowerCase(),
    },
  ];
}

interface OrvestoTrackProps {
  article?: Article;
  topicTitle?: string;
  category?: string;
  page: "topnews" | "category" | "latest" | "topic" | "article";
}

const ViewEvent = ({
  article,
  page,
  topicTitle,
  category,
}: OrvestoTrackProps) => {
  useEffect(() => {
    switch (page) {
      case "topnews":
        orvestoTrack(topnewsViewEvent());
        break;
      case "category":
        orvestoTrack(categoryViewEvent(category));
        break;
      case "latest":
        orvestoTrack(latestViewEvent());
        break;
      case "topic":
        orvestoTrack(topicViewEvent(topicTitle));
        break;
      case "article":
        orvestoTrack(articleViewEvent(article));
        break;
    }
  }, [page, article, category, topicTitle]);

  return null;
};

export const OrvestoTrackViewEvent = (props: OrvestoTrackProps) => {
  const analyticsConsent = useTcfPermission("CMP:analytics");

  if (!analyticsConsent) {
    return null;
  }

  return <ViewEvent {...props} />;
};
