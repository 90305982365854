import type { AstApnTag } from "@omni/ads";

import { getApnTag } from ".";

const alterImageSize = (container: HTMLDivElement) => {
  const img = container.querySelector("img") as HTMLImageElement;

  const style: HTMLStyleElement = document.createElement("style");
  style.innerText =
    ".fullscreenAd {width: 100vw; height: 100vh; background-repeat: no-repeat; background-position: center; background-size: cover;}";

  const div: HTMLDivElement = document.createElement("div");
  div.classList.add("fullscreenAd");
  div.style.backgroundImage = "url(" + img.src + ")";

  img.parentNode?.replaceChild(div, img);
  container.appendChild(style);
  return container.innerHTML;
};

const getContent = (targetId: string, appnexus: AstApnTag) => {
  return appnexus.requests.tags[targetId].adResponse.ads[0].rtb.banner.content;
};

const changeContent = (content: string) => {
  try {
    const container: HTMLDivElement = document.createElement("div");
    container.innerHTML = content;

    return alterImageSize(container);
  } catch (e) {
    console.error(e);
  }
  return content;
};

const setCustomFullscreenStyles = async (targetId: string) => {
  const appnexus = await getApnTag();

  try {
    appnexus.requests.tags[targetId].adResponse.ads[0].rtb.banner.content =
      changeContent(getContent(targetId, appnexus));
  } catch (e) {
    console.error(e);
  }
};

export { setCustomFullscreenStyles };
