"use client";

import { useState, useEffect, useRef } from "react";
import cn from "classnames";

import type { ResourceComponent } from "../../ArticleContent/resources/Resource/types";
import type { Ad } from "../../../types/ads/adResource";

import styles from "./SponsoredBanner.module.scss";

const SponsoredBanner: ResourceComponent<Ad> = ({
  resource,
  hasMargin = true,
}) => {
  const [isFloating, setIsFloating] = useState(false);
  const bannerRef = useRef<HTMLAnchorElement | null>(null);
  // Since the bannerRef is positioned fixed we use a reference div to observe
  // the position in the viewport to decide when the banner should "float"
  const referenceRef = useRef<HTMLDivElement | null>(null);

  const imageUrl = resource.placementRules[0].sponsor_data?.image_url;
  const { red, green, blue } =
    resource.placementRules[0].sponsor_data?.color || {};

  useEffect(() => {
    const referenceDiv = referenceRef.current;
    if (!referenceDiv) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFloating(!entry.isIntersecting);
      },
      { threshold: 0, rootMargin: "-10px" },
    );

    observer.observe(referenceDiv);

    return () => {
      observer.unobserve(referenceDiv);
    };
  }, []);

  if (imageUrl) {
    return (
      <>
        <div ref={referenceRef} />
        <a
          ref={bannerRef}
          href={resource.placementRules[0].sponsor_data?.link_url}
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundColor: `rgb(${red},${green},${blue})`,
          }}
          className={cn(
            styles.sponsoredBanner,
            isFloating && styles.floating,
            hasMargin && styles.margin,
          )}
          target="_blank"
          rel="noreferrer"
        />
      </>
    );
  }

  return null;
};

export { SponsoredBanner };
